html {
  --section-background-color: linear-gradient(
    to bottom left,
    rgba(20, 11, 11, 0.582),
    rgba(30, 9, 13, 0.904)
  );

  --image-gradient: linear-gradient(
    to bottom left,
    rgba(23, 20, 20, 0.678),
    rgba(15, 12, 33, 0.863)
  );

  --text-color-orange: #f19a3e;
}

.orange {
  color: var(--text-color-orange) !important;
}

button:focus {
  box-shadow: none !important;
}

/* Preloader */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: #10041c;
  background-image: url(./assets/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #025a6c;
}

::-webkit-scrollbar-thumb {
  background: #012f38;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #03afd0;
  border-radius: 10px;
}

/* Navbar */
.sticky {
  background-color: #403233aa !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgba(10, 5, 33, 0.15) !important;
  backdrop-filter: blur(10px) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 8px 24px !important;
  font-size: 24px !important;
}

.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #f19a3e !important;
  height: 3px !important;
  width: 30px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span {
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(45deg) !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-45deg) !important;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem !important;
    background-color: #3a2a2cdd !important;
  }

  .navbar-nav .nav-item a::after {
    display: none !important;
  }
}

.navbar-nav .nav-link {
  color: white !important;
}

.nav-link {
  padding: 1rem !important;
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 3px;
  width: 0;
  border-radius: 5px;
  background: #f19a3e;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
  transform-origin: center;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
  transform-origin: center;
}

/* Home */

#tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
}

.home-section {
  z-index: -1;
  padding-bottom: 30px !important;
  padding-top: 30px !important;
}

.home-content {
  padding: 100px 0 50px !important;
  color: #f19a3e;
  text-align: left;
}

.heading {
  font-size: 48px;
  padding-left: 50px;
}

@media (max-width: 767px) {
  .heading {
    font-size: 36px;
    padding-left: 30px;
  }
}

.Typewriter__wrapper {
  font-size: 28px !important;
  color: #8db38b !important;
  font-weight: 700 !important;
}
.Typewriter__cursor {
  font-size: 30px !important;
  color: #8db38b !important;
}

@media (max-width: 767px) {
  .Typewriter__wrapper {
    font-size: 26px !important;
    font-weight: 500 !important;
    position: absolute !important;
  }
  .Typewriter__cursor {
    display: none !important;
  }
}

.avatar {
  justify-content: center !important;
  padding-top: 9em !important;
}

@media (max-width: 767px) {
  .avatar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
}

.home-about-section {
  position: relative;
  padding-bottom: 50px !important;
  padding-top: 20px !important;
}

.home-about-description {
  color: white !important;
  padding-top: 100px !important;
  padding-bottom: 20px !important;
  text-align: center;
}

.home-about-body {
  padding-top: 50px;
  font-size: 18px;
  text-align: left;
}

.home-about-social {
  text-align: center !important;
  padding-top: 25px;
  color: white !important;
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 15px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  line-height: 2;
  background: #403233bb;
  border-radius: 50%;
  transition: 0.2s;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #3a0b0b;
  transition: 0.2s;
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #f19a3e;
}

.home-social-icons:hover {
  color: #7c1a1a;
  box-shadow: 0 0 5px #f19a3e;
  text-shadow: 0 0 2px #f19a3e;
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.icon-color {
  color: #f19a3e !important;
}

/* Footer */
.footer {
  background-color: rgb(22, 4, 4);
  bottom: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 8px !important ;
}
.footer-copywright {
  text-align: center !important;
}

.footer-body {
  z-index: 1;
  text-align: center !important;
}

@media (max-width: 767px) {
  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 14px;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}
.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}

.blockquote-footer {
  color: #c08888 !important;
}

/* About */
.about-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

.tech-icons {
  font-size: 60px;
  margin: 15px;
  padding: 10px;
  opacity: 0.9;
  border: 2px solid #f19a3eaa;
  vertical-align: middle;
  text-align: center;
  border-radius: 5px;
  box-shadow: 4px 4px 4px 4px #f19a3e18;
  display: table;
  overflow: hidden;
  transition: all 0.3s ease 0s;
}

@media (max-width: 767px) {
  .tech-icons {
    margin: 10px !important;
  }
}

.tech-icons:hover {
  transform: scale(1.05);
  overflow: hidden;
  border: 2px solid #fbb76fe4;
  box-shadow: 4px 4px 4px 4px #f19a3e54;
}
.tech-icon-images {
  padding: 20px;
  line-height: 1.5;
}

.quote-card-view {
  border: none !important;
  color: white !important;
  background-color: transparent !important;
}

.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

@media (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
}

/* Contact Form */
form {
  margin-top: 2rem;
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background-color: rgba(23, 20, 20, 0.678);
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

form label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  color: white;
  font-size: 20px;
}

form input {
  width: 100%;
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  margin-bottom: 1rem !important;
  font-size: 20px !important;
}

form button {
  background-color: var(--text-color-orange);
  color: white;
  border: none;
  font-size: 22px !important;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

form button:hover {
  background-color: #f19a3eaa;
}

.cv-download-button {
  background-color: var(--text-color-orange);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cv-download-button:hover {
  background-color: #f19a3eaa;
}
